import React from "react"
import PageLayout from "../components/PageLayout"
import PostContent from "../components/PostContent"

const Page = ({ uri }) => {
  const html = `
    <h1>お探しのページは見つかりませんでした</h1>
    <p>このページは現在ご覧いただけません。移動もしくは削除されている可能性があります。恐れ入りますが、あらためて目的のページをお探しください。</p>
    <p><a href="/">ホームに移動</a></p>
  `

  return (
    <PageLayout title="404: Not found" uri={uri}>
      <PostContent body={html} />
    </PageLayout>
  )
}

export default Page
